import { useRouter } from 'next/router'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Head from 'next/head'
import Link from 'next/link'
import { 
  Panel,
  Card,
  InputField,
  ButtonLink,
  Button,
  Grid, 
  Item 
} from '../styles/global.elements'
import ProceduresSection from '../components/main/proceduresSection'
import Breadcrumbs from '../components/breadcrumbs'

export default function Home() {
  const router = useRouter()
  const token = useSelector(
    state => state.root.auth && state.root.auth.token)
  const [ coverId, setCoverId ] = useState("")
  const [ rut, setRut ] = useState("")
  const isIE = /*@cc_on!@*/false || !!document.documentMode

  const handleKeypress = e => {
    if (e.charCode === 13) {
      handleSubmit()
    }
  }

  const handleKeypressRut = e => {
    if (e.charCode === 13) {
      handleSubmitRut()
    }
  }

  const handleSubmit = () => {
    router.push({
      pathname: '/queries/coverStatus',
      query: { coverId }
    })
  }

  const handleSubmitRut = () => {
    router.push({
      pathname: '/queries/coverSearch',
      query: { rut }
    })
  }

  return (
    <>
      <Head>
        <title>Conservador de Bienes Raíces de Puerto Varas</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Breadcrumbs 
        crumbs={[
          { text: "Inicio", link: "/" }
        ]}
      />
      <Panel variant="dark-transparent">
        <h2>Conservador de Bienes Raíces de Puerto Varas</h2>
        {!token && (
          <>
            <p>Regístrate para recibir todas las ventajas de nuestros servicios</p>
            <ButtonLink href="/auth/login" mr=".8rem">
              Iniciar sesión
            </ButtonLink>
            <ButtonLink href="/auth/register" secondary>
              Regístrate
            </ButtonLink>
          </>          
        )}
        <Card variant="dark-grey" width={["100%", "70%"]} m="auto" mt="2rem">
          <p>Consultar estado de carátulas</p>
          <Grid justify="flex-start" align="stretch" breakpoint="xl">
            <Item size="10" style={{ textAlign: 'left' }}>
              <InputField
                name="Consulta de carátula" 
                placeholder="Ej: 156760" 
                title="Número de carátula"
                type="number"
                value={coverId}
                onChange={e => setCoverId(e.target.value)}
                onKeyPress={handleKeypress}
              />
            </Item>
            <Item mt="1.5rem">
              <Button 
                onClick={handleSubmit} 
                m=".4rem .8rem"
              >
                Consultar
              </Button>              
            </Item>
          </Grid>
          <Grid justify="flex-start" align="stretch" breakpoint="xl">
            <Item size="10" style={{ textAlign: 'left' }}>
              <InputField
                name="Consulta de carátula por rut" 
                placeholder="Ej: 11111111" 
                title="Buscar carátula por rut (2023-2024)"
                type="number"
                value={rut}
                onChange={e => setRut(e.target.value)}
                onKeyPress={handleKeypressRut}
              />
            </Item>
            <Item mt="1.5rem">
              <Button 
                onClick={handleSubmitRut} 
                m=".4rem .8rem"
              >
                Consultar
              </Button>              
            </Item>
          </Grid>
        </Card>
      </Panel>
      {isIE && 
        <Panel mt="2rem">
          <h2>Comunicado</h2>
          <p>
            El navegador Internet Explorer no es soportado por este sitio web, 
            los navegadores soportados por la página son.
          </p>
          <ul>
            <li>Microsoft Edge</li>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Opera / Opera GX</li>
          </ul>
        </Panel>
      }
      <Grid justify="center" align="stretch" breakpoint="sm">
        <Card mt="2rem" width={["100%", "50%"]}>
          <img src="/images/comunicado-traslado.jpg" alt="" style={{ width: "100%" }}/>
        </Card>
      </Grid>
      <ProceduresSection />
    </>
  )
}
